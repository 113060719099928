.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  & > span {
    margin-top: 5px;
  }

  .input-container {
    position: relative;

    .input {
      border: none;
      height: 50px;
      padding: 0px 20px 0px 20px;
      font-size: 16px;
      font-weight: 600;
      width: 100%;

      &::-ms-clear {
        display: none;
      }

      &.default {
        background: $brand-secondary;
        border-radius: 4px;
        color: $brand-text;

        &::-webkit-input-placeholder {
          color: $brand-text;
          font-weight: 400;
        }

        &:-moz-placeholder {
          color: $brand-text;
          font-weight: 400;
        }

        &::-moz-placeholder {
          color: $brand-text;
          font-weight: 400;
        }

        &:-ms-input-placeholder {
          color: $brand-text;
          font-weight: 400;
        }

        &::-ms-input-placeholder {
          color: $brand-text;
          font-weight: 400;
        }

        &::placeholder {
          color: $brand-text;
          font-weight: 400;
        }
      }

      &.search {
        background: #fff;
        color: $brand-text-dark;
        height: 55px;
        padding: 0px 40px 0px 60px;
      }

      &.clean {
        border-radius: 0;
      }

      &.input--error {
        border: #dcb2b2 solid 2px;
      }
    }

    .input-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      height: 100%;
      padding: 20px;

      &.icon-right {
        right: 0;
      }

      &.icon-left {
        left: 0;
      }
    }
  }

  .input-label-container {
    margin-top: 5px;
  }
}
