.app-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: $brand-secondary;
  width: 100%;
  height: 100%;
  min-width: 600px;
  min-height: 600px;

  .header-thing {
    background-color: $brand-primary;
    height: 220px;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    min-width: 600px;
  }

  .app-container {
    height: 100%;
    width: 100%;
    z-index: 1;
    min-width: 600px;

    .logo-container {
      height: 5%;
      margin-bottom: 24px;
      display: flex;
      min-height: 35px;
    }

    .app-content {
      background: $brand-default;
      height: 100%;
      width: 100%;
      z-index: 1;
      box-shadow: 0 10px 20px rgba(208, 208, 208, 0.19),
        0 4px 5px rgba(171, 167, 167, 0.23);
      border-radius: 4px;
    }
  }
}

@media (max-width: $break-point-small) {
  .app-wrapper {
    padding: 0px 0px 0px 0px;
    background: green;

    .app-container {
      .logo-container {
        padding: 15px 0px 0px 20px;
      }

      .app-content {
        border-radius: 0px;
      }
    }
  }
}
