input,
div {
  outline: none;
}

.app {
  height: 100%;
  min-height: 100%;
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.transition-group-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fadeIn {
  animation: fadeIn 0.5s;
}

.confirm-popup {
  text-align: center;
  width: 360px;
  padding: 40px;
  background: $brand-primary;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: $brand-default;
  border-radius: 4px;

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fab-container {
  bottom: 1px !important;
  position: absolute !important;
  margin: 1em !important;
  left: 1px !important;
  right: auto !important;
  z-index: 9;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #dfdfdf;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
}
