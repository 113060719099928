$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';

.call-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  z-index: 999;
  background-color: #4064d1;

  &.active {
    opacity: 1;
    z-index: auto;

    .video-control {
      z-index: auto;
    }
  }

  .video-control {
    position: absolute;
    bottom: 20px;
    height: 72px;
    width: 100%;
    text-align: center;
    opacity: 0;
    z-index: -1;

    &:hover {
      opacity: 1;
    }
  }

  video {
    position: absolute;
  }

  #localVideo {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  #peerVideo {
    width: 100%;
    height: 100%;
  }

  .video-control {
    position: absolute;
    bottom: 20px;
    height: 72px;
    width: 100%;
    text-align: center;
    opacity: 0.2;
    z-index: 999;

    &:hover {
      opacity: 1;
    }
  }

  .video-control,
  .call-modal {
    .btn-action {
      $height: 50px;
      height: $height;
      width: $height;
      line-height: $height;
      margin: 0px 8px;
      font-size: 1.4em;
      text-align: center;
      border-radius: 50%;
      cursor: pointer;
      transition-duration: 0.25s;

      &:hover {
        //opacity: 0.8;
      }

      &.hangup {
        background-color: #f33;
      }

      &:not(.hangup) {
        background-color: #4064d1;

        &.disable {
          background-color: #f33;
        }
      }
    }
  }

  .hidden {
    display: none !important;
  }

  .user-call-image {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      height: 200px;
      width: 200px;
    }
  }
}
