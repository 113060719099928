*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: $font-size-base;
  box-sizing: inherit;
  user-select: none;
  color: $brand-text;
  height: 100%;
}

body {
  font-size: $font-size-base;
  font-family: $font-family;
  font-smoothing: antialiased;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  background: $brand-secondary;
  color: $brand-text;
  height: 100%;
}

input,
textarea {
  font-size: $font-size-base;
  font-family: $font-family;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 32px;
  font-weight: 600;
}

ol li,
ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  outline: none;
}

[contenteditable] {
  user-select: text;
}
