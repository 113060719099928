.donut {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 3px solid rgba($brand-primary, 0.3);
	border-top-color: $brand-primary;
	animation: 1s spin infinite linear;

	&.default {
		border: 3px solid rgba($brand-default, 0.3);
		border-top-color: $brand-default;
	}
}

.spinner {
	height: 30px;
	text-align: center;
	font-size: 10px;
}

.spinner > div {
	background: $brand-primary;
	margin: 2px;
	height: 100%;
	width: 3px;
	display: inline-block;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
	animation-delay: -1.1s;
}

.spinner .rect3 {
	animation-delay: -1.0s;
}

.spinner .rect4 {
	animation-delay: -0.9s;
}
