.sketch-wrapper {
	margin: 4px 0px 4px 0px;

	.sketch {
		width: 40px;
		height: 40px;
		background: $brand-tertiary;
		animation: forwards infinite sketchShimmer linear 1s;
		background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
		background-repeat: no-repeat;
		background-size: 800px 104px;

		&.dark {
			background: $brand-default;
			background-image: linear-gradient(to right, $brand-default 0%, $brand-tertiary 20%, $brand-default 40%, $brand-default 100%);
			background-repeat: no-repeat;
			background-size: 800px 104px;
		}

		&.circle {
			border-radius: 50%;
		}
	}
}
