.call-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  width: 357px;
  height: 337px;
  background: #f1f1f1;
  box-shadow: 20px 20px 40px rgba(133, 130, 137, 0.25);
  border-radius: 50px;
  z-index: 999;

  img {
    width: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.36));
  }

  h2 {
    color: #4064d1;
    font-size: 19px;
    text-align: center;
  }

  p {
    text-align: center;
    color: #4064d1;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  button {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.25), -4px -4px 10px #ffffff;
    font-size: 30px;
    padding-top: 5px;
    transition: all 0.4s ease-in-out;
  }

  button:hover {
    cursor: pointer;
    font-size: 35px;
  }

  button:nth-child(1) {
    color: rgb(48, 190, 35);
  }

  button:nth-child(1):hover {
    background: rgb(48, 190, 35);
    color: #fff;
  }

  button:nth-child(2) {
    color: rgb(51, 146, 255);
  }

  button:nth-child(2):hover {
    background: rgb(51, 146, 255);
    color: #fff;
  }

  button:nth-child(3) {
    color: #f33;
  }

  button:nth-child(3):hover {
    background: #f33;
    color: #fff;
  }
}
