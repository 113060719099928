.user-info-wrapper {
  display: flex;
  flex-direction: row;

  .user-info-container {
    display: flex;
    flex-direction: row;
    position: relative;

    &.column {
      flex-direction: row;
    }

    .labels-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 40px;

      .online {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 10px;
        background-color: rgb(35, 133, 10);
      }
    }

    .username-container {
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .footer-container {
      display: flex;
    }

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  .actions-container {
    transition: all 0.1s ease;
    width: 0px;
    height: 0px;

    &.visible {
      width: 28px;
      height: 28px;
    }
  }

  &:hover {
    .actions-container {
      width: 28px;
      height: 28px;
    }
  }
}
