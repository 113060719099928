.actions-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 24%;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  min-width: 285px;
  background: #fff;

  .header-container {
    min-height: 140px;
    width: 100%;
    border-radius: 4px 0px 0px 0px;
    padding: 20px;

    .header-content {
      display: flex;
      justify-content: space-between;
    }
  }

  .actions-wrapper-content {
    width: 90%;
    background-color: #fff;
  }

  .main-search-container {
    width: 90%;
    background-color: #fff;
  }

  .search-container-conner {
    border-bottom-right-radius: 50px;
  }
}
