@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes sk-stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
	}

	20% {
		transform: scaleY(1.0);
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

@keyframes sketchShimmer {
	0% {
		background-position: -468px 0
	}
	100% {
		background-position: 468px 0
	}
}
