// Global
$font-family: 'Montserrat', sans-serif;

// Colors
$brand-default: #ffffff;
$brand-primary: #0f5fff;
$brand-primary-ligth: #4283ff;
$brand-secondary: #f1f2f4;
$brand-tertiary: #dadada;
//$brand-tertiary: #dadada;
$brand-tertiary-ligth: #e8e9ea;
$brand-success: #58bd8f;
$brand-warning: #f8ab37;
$brand-danger: #da7079;

// $brand-info: #000000;
$brand-text-default: #ffffff;
$brand-text: #b1b0b0;
$brand-text-dark: #555657;
$brand-text-primary: #0f5fff;

// Sizes
$font-size-base: 16px;
$break-point-small: 650px;
$break-point-large: 1366px;
