.start-call-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 75%;
  left: 86%;
  transform: translate(-50%, -50%);
  padding-top: 20px;
  width: 357px;
  height: 350px;
  background: #fff;
  z-index: 999;

  img {
    width: 80px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  h2 {
    color: #4064d1;
    font-size: 19px;
    width: 90%;
    text-align: left;
  }

  p {
    text-align: left;
    width: 90%;
    color: rgb(0, 0, 0);
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  button {
    width: 100%;
    height: 70px;
    background: #ffffff;
    font-size: 30px;
    transition: all 0.4s ease-in-out;
  }

  button:hover {
    cursor: pointer;
    font-size: 35px;
  }

  .button-cancelcall {
    color: rgb(0, 0, 0);
  }

  .button-cancelcall:hover {
    background: #f33;
    color: #ffffff;
  }

  .button-audiocall {
    background: #4064d1;
    color: #fff;
  }

  .button-audiocall:hover {
    background: #fff;
    color: #4064d1;
  }

  .button-videocall {
    color: rgb(0, 0, 0);
  }

  .button-videocall:hover {
    background: rgb(76, 156, 161);
    color: #ffffff;
  }
}
