.drop-down-menu-wrapper {
	display: inline;

	.drop-down-menu  {
		position: absolute;
		z-index: 1;

		.drop-down-container {
			display: flex;
			right: 0;
			margin: 10px;
			transition: all 0.3s ease;
			border-radius: 4px;
			box-shadow: 0 2px 5px 0 rgba(142, 142, 142, 0.26), 0 2px 10px 0 rgba(90, 90, 90, 0.16);
			background: $brand-default;

			ul {
				padding: 10px 20px 10px 20px;
			}
		}
	}
}
