.label {
	display: block;

	&.align-center {
		text-align: center;
	}

	&.align-right {
		text-align: right;
	}

	&.danger {
		color: $brand-danger;
	}

	&.default {
		color: $brand-default;
	}

	&.dark {
		color: $brand-text-dark;
	}

	&.font-bold {
		font-weight: 700;
	}

	&.font-semi-bold {
		font-weight: 600;
	}

	&.font-medium {
		font-weight: 500;
	}

	&.font-regular {
		font-weight: 400;
	}

	&.break-word {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
