.chat-container {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  width: 76%;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
  min-width: 285px;
  background: $brand-secondary;

  .empty-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    width: 100%;
    height: 100%;
    background-color: #f5f4f4;

    img {
      width: 100px;
    }
  }

  .chat-content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 0px 4px 4px 0px;
    min-width: 285px;

    .header-container {
      display: flex;
      align-items: center;
      padding: 25px;
      height: 70px;
      min-height: 65px;
      width: 100%;
      border-radius: 0px 4px 0px 0px;
      background: #fff;
    }

    .conversation-container {
      height: 100%;
      background: #e6e6e6;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 25px 25px 25px 25px;
    }

    .footer-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 46px;
      border-radius: 0px 0px 4px 0px;
      background: #fff;
      padding: 0px 25px;
    }
  }
}
