.start-conversation-drawer-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .conversations-list-container {
    div {
      .conversation-card {
        background-color: #fff;

        .conversation-card--container {
          border-top: none;
        }
      }

      &.conversation-card:hover {
        background-color: #f1f2f4;
      }
    }
  }
}
