.drawer {
	background-color: $brand-default;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
	transform: translateX(-101%);
	transition: all .2s ease-in-out;

	&.open {
		transform: translateX(0%);
	}

	.drawer-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;

		.header {
			display: flex;
			align-items: flex-end;
			padding: 20px;
			background: $brand-primary-ligth;
			width: 100%;
			height: 140px;
		}
	}
}
