.content-editable {
  background: #f0f0f0;

  border-radius: 50px;
  color: #444;
  width: 85%;
  padding: 10px 20px 10px 20px;
  max-height: 80px;
  min-height: 46px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.call-button {
  width: 50px;
  height: 50px;
  font-size: 25px;
  color: #4064d1;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
}

.emoji-button {
  width: 50px;
  height: 50px;
  font-size: 25px;
  color: #4064d1;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
}

.message-button {
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #4064d1;

  &:hover {
    cursor: pointer;
  }
}

.content-editable[contentEditable='true']:empty:not(:focus):before {
  content: attr(placeholder);
}

.content-editable[contentEditable='true']:empty:before {
  content: attr(placeholder);
}

.message-icon {
  cursor: pointer;
}

.emoji-button {
  svg {
    font-size: 28px;
    color: #4064d1;
  }

  &:hover {
    cursor: pointer;
  }
}

.fab-callaction-button {
  background-color: #4064d1;
}
