.tag {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 22px;
	height: 22px;

	&.success {
		background: $brand-success;
	}
}
