.auth-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    padding: 30px 0px 20px 0px;

    .title {
      text-align: center;
      margin: 0px 0px 40px 0px;
      color: $brand-text;
    }

    .inputs-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .input-wrapper {
      max-width: 280px;
    }

    .info-container {
      justify-content: center;
      align-items: center;
      display: flex;
      text-align: center;
      margin: 25px 0px 0px 0px;
      font-weight: 500;
    }
  }
}
