.flash-message-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	border-radius: 4px;
	cursor: pointer;
	font-weight: 500;
	font-size: 16px;
	padding: 20px;

	animation: fadeIn 1s;

	&.error {
		background: $brand-danger;
		color: $brand-text-default;
	}

	&.success {
		background: $brand-success;
		color: $brand-text-default;
	}

	&.closed {
		display: none;
	}

	.flash-message {
		display: block;
		padding: 0;
		border: 0;
	}

	.button-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
