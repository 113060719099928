.button {
  height: 50px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  color: $brand-text-default;
  font-size: 18px;

  &.small {
    height: 35px;
  }

  &.button--primary {
    transition: background 0.5s ease, color 0.5s ease;
    background: $brand-primary;

    .spinner > div {
      background: $brand-default;
    }

    &:hover {
      background: $brand-default;
      color: $brand-text-primary;

      .spinner > div {
        background: $brand-primary;
      }
    }
  }

  &.button--default {
    transition: background 0.5s ease, color 0.5s ease;
    background: $brand-default;
    border: 2px solid $brand-default;
    color: $brand-text-primary;

    .spinner > div {
      background: $brand-primary;
    }

    &.outline {
      background: none;
      color: $brand-default;

      &:hover {
        background: $brand-default;
        color: $brand-text-primary;
      }
    }
  }

  &.button--disabled {
    transition: all 0.5s ease;
    background: $brand-secondary;
    color: $brand-text;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.button--link {
    background: none;
    color: $brand-primary;
    box-shadow: none;
    height: 30px;
  }

  &:active {
    transform: translateY(2px);
  }
}
